@font-face{
    font-family: 'Next-Bold';
    src: local('Next-Bold'), url('./ttf/Next-Bold.ttf') format('truetype');
}
@font-face{
    font-family: 'regular';
    src: local('regular'), url('./ttf/regular.ttf') format('truetype');
}
@font-face{
    font-family: 'Next-Semibold';
    src: local('Next-Semibold'), url('./ttf/Next-Semibold.ttf') format('truetype');
}
@font-face{
    font-family: 'Avenir-Heavy';
    src: local('Avenir-Heavy'), url('./ttf/Avenir-Heavy.ttf') format('truetype');
}
@font-face{
    font-family: 'Avenir-Book';
    src: local('Avenir-Book'), url('./ttf/Avenir-Book.ttf') format('truetype');
}
@font-face{
    font-family: 'Avenir-NextDemi';
    src: local('AvenirNextCondensed-DemiBold-03'), url('./ttf/AvenirNextCondensed-DemiBold-03.ttf') format('truetype');
}


@font-face{
    font-family: 'black';
    src: local('black'), url('./ttf/black.ttf') format('truetype');
}
@font-face{
    font-family: 'bold';
    src: local('bold'), url('./ttf/bold.ttf') format('truetype');
}
@font-face{
    font-family: 'medium';
    src: local('medium'), url('./ttf/medium.ttf') format('truetype');
}
@font-face{
    font-family: 'regular';
    src: local('regular'), url('./ttf/regular.ttf') format('truetype');
}
@font-face{
    font-family: 'semi';
    src: local('semi'), url('./ttf/semi.ttf') format('truetype');
}
