:focus {
    outline: 0;
}

/* input:disabled {
    opacity:.7;
    color: #000000;
    background: #dde9ee;
}

select:disabled {
    color: #55565a;
    background: #EBF5F7;
} */

.container-login{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f5f6fa; */
}
.container-image-login{
    min-width:150;
    position: relative;
}
.card-login{
    background: #fff;
    width: 400px;
    /* height: 600px; */
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    padding: 25px 25px;
    
}
.img-logo-login{
    /* max-width: 250px; */
    margin-top: 10px;
    padding:25px;
    max-width: 230px;
    width: 100%;
}
.img-ok-login{
    position: absolute;
    right: 5px;
    top: 10px;
}
.image-covid{
    z-index: 2;
    position: absolute;
    top: 30%;
    left: 90%;
    width: 350px;
}
.input-email-login{
    width: calc(100% - 5px);
    border:none;
    padding-bottom: 15px;
    padding-left: 5px;
    font-size: 16px;
}
.input-email-login::-ms-reveal,::-ms-clear{/*Delete eye in Edge chromiun*/
    display: none;
}
.border-status1{
    border-bottom: 1px solid silver;
}
.border-status2{
    border-bottom: 2px solid #2AD95A;
}
.border-status3{
    border-bottom: 2px solid #FF1973;
}
.container-input{
    /* display: flex; */
    position: relative;
    margin-top: 50px;
}
.background-login{
    height: calc(100vh - 4px);
    width: auto;
}
.container-image{
    position:absolute;
    left:0;
    top: 0;
    /* overflow: hidden; */
}
.button-login{
    position: relative;
    background-color: #1E7DFF;
    color: #fff;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    width: 170px;
    height: 50px;
    margin-top: 50px;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    padding-left: 20px;
}
.button-login:active{
    transform: scale(0.98);
}
@media only screen and (max-width: 1024px) {
    .container-image-login{
        display: none;
    }
}

@media only screen and (max-width: 1300px) {
    /* .container-image{
        width: 250px;
    } */
    .image-covid{
        top: 25%;
        left: 35%;
        width: 300px;
    }
}



/* Restore password */

.container_login{
    background-color: #f3f5f9;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login_card{
    background-color: #fff;
    border-radius: 20px;
    width: 100%;
    height: 450px;
}


.login_input{
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    padding-bottom: 8px;
    font-size: 16px;
    font-family: regular;
    border-bottom: 1px solid #ADB6C5;
}

.login_input:focus{
    border-bottom-color: #2fd25a;
}

.login_input_text{
    position: absolute;
    top: 0;
    left: 0;
    line-height: 147.6%;
    transition: top .3s cubic-bezier(.49,.35,.38,.91);
    font-size: 16px;
    cursor: text;
    font-family: regular;
}

/* Email or pass error */
.login_input_error{
    border-top: none;
    border-left: none;
    border-right: none;
    width: 100%;
    padding-bottom: 8px;
    font-size: 16px;
    font-family: regular;
    border-bottom-color: #FF1973
}

.login_input::-ms-reveal,::-ms-clear{/*Delete eye in Edge chromiun*/
    display: none;
}
.login_input_error::-ms-reveal,::-ms-clear{/*Delete eye in Edge chromiun*/
    display: none;
}

/* Disabled boxshadow red on inputs */
.login_input:-moz-ui-invalid{
    box-shadow: 0 0 1.5px 1px transparent;
}
.login_input_error:-moz-ui-invalid{
    box-shadow: 0 0 1.5px 1px transparent;
}

.login_input:focus + .login_input_text,
.login_input:invalid + .login_input_text,
.login_input_error:focus + .login_input_text,
.login_input_error:invalid + .login_input_text{
    top: -20px;
    font-size: 14px;
    color: #6b6e75;
    outline: 0;
    user-select: none;
}

.login_button{
    position: relative;
    background-color: #0055af;
    color: #fff;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    width: 170px;
    height: 50px;
    margin-top: 50px;
    font-size: 17px;
    font-weight: 600;
    text-align: left;
    padding-left: 20px;
    font-family: semi;
}
.login_button:active{
    transform: scale(0.98);
}


/* ----------------------------------------------------------------- */
/* -------------------DELETE INFO OF GOOGLE MAPS-------------------- */
/* ----------------------------------------------------------------- */
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
.gm-style-cc{
    display:none;
}
img[src="https://maps.gstatic.com/mapfiles/api-3/images/google_white5.png"], a[href^="http://maps.google.com/maps"]{
    display:none !important;
}